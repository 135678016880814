import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { useGetPostsQuery } from 'store/api/configService/postController';
import { viewModeSelector } from 'store/slices/viewMode';

import { stripHtml } from 'utils/formatters';
import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';

import { categories } from 'components/config/newsAndUpdates';
import { PAGE_PATH } from 'components/config/pages';

import styles from './NewsAndUpdates.module.scss';

const updatesSizes = {
  [VIEW_MODE.SIMPLE]: 4,
  [VIEW_MODE.WIDE]: 5,
};

const newsSize = 8;

const NewsAndUpdates = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const getPostTypeQuery = useCallback((type) => `postType=${type};`, []);

  const { data: updatesData, isLoading: updatesLoading } = useGetPostsQuery({
    size: updatesSizes[viewMode],
    query: getPostTypeQuery(categories.updates.postType),
  });

  const { data: newsData, isLoading: newsLoading } = useGetPostsQuery({
    size: newsSize,
    query: getPostTypeQuery(categories.news.postType),
  });

  const mainUpdate = useMemo(() => updatesData?.content?.[0], [updatesData]);

  if (updatesLoading || newsLoading) return <Loader />;

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>Tech and product updates</div>
          <ArrowCircleLink
            path={findPathPages(PAGE_PATH.NEWS_CATEGORY).replace(
              ':category',
              'updates',
            )}
            title="All Updates"
          />
        </div>
        <div className={styles.updates}>
          {mainUpdate && (
            <Link
              className={classNames(styles.main, styles.link)}
              to={findPathPages(PAGE_PATH.NEWS_ITEM)
                .replace(':category', 'updates')
                .replace(':item', mainUpdate.id)}
            >
              <img src={mainUpdate.img} alt="main update" />
              <div className={styles.info}>
                <div className={styles.title}>{mainUpdate.title}</div>
                <div className={styles.description}>
                  {stripHtml(mainUpdate.shortDescription)}
                </div>
                <div>{mainUpdate.date}</div>
              </div>
            </Link>
          )}
          <div>
            {updatesData?.content?.slice(1).map((item, index) => (
              <Link
                className={classNames(styles.mainLink, styles.link)}
                key={index}
                to={findPathPages(PAGE_PATH.NEWS_ITEM)
                  .replace(':category', 'updates')
                  .replace(':item', item.id)}
              >
                <img src={item.img} alt="news update" />
                <div className={styles.info}>
                  <div className={styles.title}>{item.title}</div>
                  <div>{item.date}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.newsSection}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>News</div>
            <ArrowCircleLink
              path={findPathPages(PAGE_PATH.NEWS_CATEGORY).replace(
                ':category',
                'news',
              )}
              title="All News"
            />
          </div>
          <div className={styles.list}>
            {newsData?.content?.map((item, index) => (
              <List {...item} key={index} category="news" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsAndUpdates;
