import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { downloadMaterialsSelector } from 'store/slices/downloadMaterials';

import DownloadDialog from 'components/DownloadMaterials/Dialog/DownloadDialog/DownloadDialog';
import ErrorDialog from 'components/DownloadMaterials/Dialog/ErrorDialog/ErrorDialog';
import DownloadProcess from 'components/DownloadProcess/DownloadProcess';
import Alert from 'components/UI/Alert/Alert';

import { BASE_PATH } from 'components/config/pages';

import styles from 'components/Layouts/Content/ContentLayout.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
const ContentLayout = ({ children }) => {
  const { pathname } = useLocation();

  const wrapperRef = useRef(null);

  const error = useSelector(downloadMaterialsSelector.getError);
  const dialogData = useSelector(downloadMaterialsSelector.getDialogData);

  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className={styles.wrapper} id="content-wrapper" ref={wrapperRef}>
      {children}
      <Alert />
      {!error ? (
        !!Object.keys(dialogData).length && (
          <DownloadDialog
            pageView={pathname.includes(`${BASE_PATH}download-materials/`)}
          />
        )
      ) : (
        <ErrorDialog />
      )}
      <DownloadProcess />
    </div>
  );
};

ContentLayout.propTypes = propTypes;
export default ContentLayout;
