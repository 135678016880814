import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { useGetPostsQuery } from 'store/api/configService/postController';

import { findPathPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';
import NotFound from 'components/pages/NotFound/NotFound';

import { categories } from 'components/config/newsAndUpdates';
import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/NewsAndUpdates/Category/Category.module.scss';
import baseStyles from 'components/pages/NewsAndUpdates/NewsAndUpdates.module.scss';

const itemsSize = 16;

export const Category = () => {
  const { category } = useParams();

  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState([]);

  const [ref, inView] = useInView({});

  const { data, isLoading, isFetching } = useGetPostsQuery({
    size: itemsSize,
    page: currentPage,
    query: `postType=${categories[category]?.postType};`,
  });

  useEffect(() => {
    if (inView && !isFetching && currentPage < data?.totalPages - 1) {
      setCurrentPage((prevState) => prevState + 1);
    }
  }, [inView]);

  useEffect(() => {
    data?.content && setItems((prev) => [...prev, ...data.content]);
  }, [data]);

  if (isLoading) return <Loader />;

  if (!categories[category]) return <NotFound />;

  return (
    <div className={baseStyles.content}>
      <div className={styles.back}>
        <ArrowCircleLink
          path={findPathPages(PAGE_PATH.NEWS)}
          title="Back"
          direction="left"
        />
      </div>
      <div className={baseStyles.header}>
        <div className={baseStyles.title}>{categories[category].title}</div>
      </div>
      <div className={baseStyles.list}>
        {items.map((item) => (
          <List key={item.id} category={category} {...item} />
        ))}
      </div>
      <div ref={ref}>{isFetching && <Loader />}</div>
    </div>
  );
};

export default Category;
