import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { step } from 'store/slices/onboarding';

import RoleContentController from 'components/RoleContentController/RoleContentController';
import Button from 'components/UI/Button/Button';

import { ROLES } from 'config/organization';

import { ReactComponent as Logo } from 'images/Logo/Logo.svg';

import styles from 'components/OnBoarding/OnBoarding.module.scss';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
};

const Welcome = ({ handleClose, nextStep }) => {
  const dispatch = useDispatch();

  const handleNext = useCallback(() => dispatch(step(nextStep)), []);

  return (
    <>
      <div className={styles.welcome}>
        <Logo width={74} />
        <div className={styles.title}>
          Welcome to{' '}
          <RoleContentController
            roles={[ROLES.defaultUser, ROLES.mediaAffiliate]}
          >
            {['Evoplay Client Area', 'AFFILIATE HUB']}
          </RoleContentController>
        </div>
        <div className={styles.subTitle}>
          Let us help you get to know our product better.
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button onClick={handleNext}>Start</Button>
      </div>
    </>
  );
};

Welcome.propTypes = propTypes;
export default Welcome;
