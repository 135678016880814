import { Fragment, useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useRole } from 'hooks/useRole';

import RoleContentController from 'components/RoleContentController/RoleContentController';

import { itemsByRole } from 'components/config/faq';
import { EMAILS, ROLES } from 'config/organization';

import Owl from 'images/Support/owl.png';

import styles from './Support.module.scss';

const Support = () => {
  const [expand, setExpand] = useState('');

  const role = useRole();

  const handleClick = useCallback(
    (item) => (item === expand ? setExpand('') : setExpand(item)),
    [expand],
  );

  const items = useMemo(() => itemsByRole[role], [role]);

  return (
    <>
      <div className={styles.support}>
        <img src={Owl} alt="owl" />
        <RoleContentController
          roles={[ROLES.defaultUser, ROLES.mediaAffiliate]}
        >
          {[
            <Fragment key="text-default">
              <div className={styles.title}>Support</div>
              <div className={styles.description}>
                <p>
                  If you have any questions related to games, promotions,
                  updates, or access, please contact your Key Account Manager.
                </p>
                <p>
                  Alternatively, please email us at&nbsp;
                  <a href={`mailto:${EMAILS.support}`}>{EMAILS.support}</a>, and
                  we will respond as soon as possible.
                </p>
              </div>
            </Fragment>,
            <Fragment key="text-media">
              <div className={styles.title}>Need Help?</div>
              <div className={styles.description}>
                <p>
                  If you have any questions about games, updates, or access,
                  just email us at{' '}
                  <a href={`mailto:${EMAILS.business}`}>{EMAILS.business}</a>.
                </p>
                <p>We’ll get back to you as quickly as possible!</p>
              </div>
            </Fragment>,
          ]}
        </RoleContentController>
      </div>
      <div className={styles.faq}>
        <div className={styles.content}>
          <div className={styles.title}>FAQ</div>
          {items.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.item, {
                [styles.open]: expand === item.question,
              })}
            >
              <div
                className={styles.header}
                onClick={() => handleClick(item.question)}
              >
                <div className={styles.question}>
                  <div className={styles.index}>
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </div>
                  <div>{item.question}</div>
                </div>
                <div className={styles.arrowWrapper}>
                  <div
                    className={classNames(styles.arrow, {
                      [styles.arrowUp]: expand === item.question,
                      [styles.arrowDown]: expand !== item.question,
                    })}
                  ></div>
                </div>
              </div>
              {expand === item.question && (
                <div
                  className={styles.answer}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Support;
