import { GrowthBook } from '@growthbook/growthbook-react';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-Qofbb6URsuymxYQ',
  enableDevMode: true,
  subscribeToChanges: true,
});

export default growthbook;
