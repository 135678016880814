import { findPathPages } from 'utils/pages';
import { isMediaAffiliate } from 'utils/roles';

import { PAGE_PATH } from 'components/config/pages';

import { ReactComponent as Account } from 'images/Icons/Account.svg';
import { ReactComponent as Bingo } from 'images/Icons/Bingo.svg';
import { ReactComponent as BrandAssets } from 'images/Icons/BrandAssets.svg';
import { ReactComponent as CalendarIcon } from 'images/Icons/Calendar.svg';
import { ReactComponent as Documentation } from 'images/Icons/Documentation.svg';
import { ReactComponent as Game } from 'images/Icons/Game.svg';
import { ReactComponent as Home } from 'images/Icons/Home.svg';
import { ReactComponent as News } from 'images/Icons/News.svg';
import { ReactComponent as Promotions } from 'images/Icons/Promotions.svg';
import { ReactComponent as Support } from 'images/Icons/Support.svg';
// import { ReactComponent as TournamentsB2B } from 'images/Icons/TournamentsB2B.svg';
import { ReactComponent as Tutorials } from 'images/Icons/Tutorials.svg';

export const items = [
  {
    title: 'Home',
    path: findPathPages(PAGE_PATH.HOME),
    icon: (props) => <Home {...props} />,
    getOrder: () => 0,
  },
  {
    title: 'Roadmap',
    icon: (props) => <CalendarIcon {...props} width={24} height={24} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPathPages(PAGE_PATH.ROADMAP).replace(':provider', 'evoplay'),
      },
      {
        title: 'Slotopia',
        path: findPathPages(PAGE_PATH.ROADMAP).replace(':provider', 'slotopia'),
      },
    ],
    getOrder: (role) => (!isMediaAffiliate(role) ? 1 : 2),
  },
  {
    title: 'All games',
    icon: (props) => <Game {...props} width={24} height={24} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPathPages(PAGE_PATH.ALL_GAMES).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPathPages(PAGE_PATH.ALL_GAMES).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
    getOrder: (role) => (!isMediaAffiliate(role) ? 2 : 1),
  },
  {
    title: 'Bingo',
    path: findPathPages(PAGE_PATH.BINGO),
    icon: (props) => <Bingo {...props} />,
    getOrder: (role) => (!isMediaAffiliate(role) ? 3 : 4),
  },
  {
    title: 'Promotions',
    icon: (props) => <Promotions {...props} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPathPages(PAGE_PATH.PROMOTIONS).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPathPages(PAGE_PATH.PROMOTIONS).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
    getOrder: (role) => (!isMediaAffiliate(role) ? 4 : 3),
  },
  // {
  //   title: 'B2B Tournaments',
  //   path: findPathPages(PAGE_PATH.TOURNAMENTS),
  //   icon: (props) => <TournamentsB2B {...props} />,
  //   isVisible: ({ role, hasTournamentsAccess }) =>
  //     !isMediaAffiliate(role) && hasTournamentsAccess,
  //   getOrder: () => 5,
  // },
  {
    title: 'Tutorials',
    icon: (props) => <Tutorials {...props} />,
    subNav: [
      {
        title: 'Bonus House',
        path: findPathPages(PAGE_PATH.TUTORIALS).replace(
          ':system',
          'bonusHouse',
        ),
      },
      {
        title: 'Back Office',
        path: findPathPages(PAGE_PATH.TUTORIALS).replace(
          ':system',
          'backOffice',
        ),
      },
      {
        title: 'Bingo',
        path: findPathPages(PAGE_PATH.TUTORIALS).replace(':system', 'bingo'),
      },
    ],
    isVisible: ({ role }) => !isMediaAffiliate(role),
    getOrder: () => 6,
  },
  {
    title: 'Documentation',
    path: findPathPages(PAGE_PATH.DOCUMENTATION),
    icon: (props) => <Documentation {...props} />,
    isVisible: ({ role }) => !isMediaAffiliate(role),
    getOrder: () => 7,
  },
  {
    title: 'News and Updates',
    path: findPathPages(PAGE_PATH.NEWS),
    icon: (props) => <News {...props} />,
    getOrder: () => 8,
  },
  {
    title: 'Brand Assets',
    icon: (props) => <BrandAssets {...props} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPathPages(PAGE_PATH.BRAND_ASSETS).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPathPages(PAGE_PATH.BRAND_ASSETS).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
    getOrder: () => 9,
  },
  {
    title: 'Support',
    path: findPathPages(PAGE_PATH.SUPPORT),
    icon: (props) => <Support {...props} />,
    getOrder: () => 10,
  },
  {
    title: 'Personal Account',
    path: findPathPages(PAGE_PATH.ACCOUNT),
    icon: (props) => <Account {...props} />,
    getOrder: () => 11,
  },
  {
    title: 'Compliance',
    path: findPathPages(PAGE_PATH.COMPLIANCE),
    icon: (props) => <Documentation {...props} />,
    //TODO: change icon
    isVisible: ({ role }) => !isMediaAffiliate(role),
    getOrder: () => 12,
  },
];
