import { ROLES } from 'config/organization';

export const itemsByRole = {
  [ROLES.defaultUser]: [
    {
      question: 'How is a financial report prepared?',
      answer:
        'The financial report is prepared at the beginning of each day for the previous day. It encompasses all the successful callbacks that occurred from 00:00:00 UTC to 23:59:59 UTC. The GGR is calculated using the formula: Bet-Win.',
    },
    {
      question:
        'Which currencies are currently available? And can I add a new currency?',
      answer:
        '<p>All the supported currencies are automatically enabled by default for all of our partners. You can find the list of supported currencies in your Client Area on the Documentation page under the "Supported Currencies" file.</p>' +
        'We can add currencies that are listed on the following sources:<br/>' +
        'for fiat currencies - <a href="https://openexchangerates.org/" target="_blank">https://openexchangerates.org/</a><br/>' +
        'for crypto currencies - <a href="https://coinmarketcap.com/" target="_blank">https://coinmarketcap.com/</a><br/>' +
        'Please note that the base currency is USD.',
    },
    {
      question:
        'Which languages does the system support? How can I change the language?',
      answer:
        '<p>Our games support a wide range of languages. To determine which languages are available for a particular game, you can use the Game/getAvailableLanguages API method.</p>' +
        '<p>The supported languages for each game are specified in your Client Area. You can find this information on the page of each game or by navigating to the All Games page and clicking the "Download materials" button in the "Games Data" file.</p>' +
        '<p>By default, all available languages are enabled. However, if you want to change the language, you can do so by providing the desired language value in the "settings[language]" parameter when using the Game/getURL or Game/getIframeURLAdvanced method to generate the game launch link.</p>' +
        'If you happen to pass a language value that is not supported, the game will be launched in English, which serves as the default language.',
    },
    {
      question: 'How can I find out bets in various currencies?',
      answer:
        'Information about bets (max, min, default) in different currencies can be found in your Client Area on the page of each game.',
    },
    {
      question:
        'How to change the bet limits? Can we change the bet limits for one game?',
      answer:
        'We have the flexibility to modify the bet limits for all games, except for socket games, based on your specific requests. These limits can be adjusted either for the entire project or for individual games, depending on your preferences. You can specify the desired values for the bet limits using the following parameters:<br/>' +
        '-min bet;<br/>' +
        '-default bet;<br/>' +
        '-max bet;<br/>' +
        '-max win.',
    },
    {
      question: 'How to get an HTTPS game launch link?',
      answer:
        'You should pass "settings[https]=1" parameter in the Game/getURL or Game/getIframeURLAdvanced method while generating the game launch link.',
    },
    {
      question:
        'Round and Action(Event) difference. How to check if the round is over?',
      answer:
        '<p>One round can consist of multiple actions, for instance, when a player wins Free Spins. Each of these Free Spins will be considered a game action within that single round.</p>' +
        '<p>To determine which game action marks the end of the round, you should refer to the "final_action" parameter in the win callbacks. If its value is equal to 1, then the round is over, and there won\'t be any more actions to follow.</p>' +
        'It\'s important to note that all game actions within one round will share the same "round_id" but will have different "action_ids"."',
    },
    {
      question: 'What are the callback sending scenarios?',
      answer:
        '<p>Each game action consists of both a bet and win callback.</p>' +
        '<p>We offer two callback sending scenarios from our side:</p>' +
        '<p>1. We send all the bets, including zero bets, and only non-zero wins.</p>' +
        '<p>2. We send all the wins, including zero wins, and only non-zero bets.</p>' +
        "By default, the second scenario is enabled, but we can change it upon the partner's request.",
    },
    {
      question: 'Do you have a retry policy, and if so, how does it work?',
      answer:
        "<p>During the first hour after 5, 10, 20, and 30 minutes, we resend only win and refund callbacks. Subsequently, we continue sending these callbacks every hour for the next 24 hours. However, if a callback wasn't processed successfully within the 24-hour window, we won't resend it any further.</p>" +
        "It's important to note that we don't follow the same resend approach for bet callbacks as we do for wins and refunds. If an error occurs when a customer plays the game and we don't receive a successful response, we'll send a refund for that particular bet instead.",
    },
    {
      question:
        'What happens to the pending rounds if a player leaves the game?',
      answer:
        "<p>If a player leaves the game before the round is completed, they can still end it within the session lifetime. However, if the game round isn't finished in time, our system will automatically close the pending round by sending a zero win callback.</p>" +
        'By default, the game round remains open for 5 days. However, upon request, this value can be customised. The minimum value we can set for the round duration is 2 hours.',
    },
    {
      question:
        "What value should be passed in the 'game' parameter in the game launch API method?",
      answer:
        'The game can be launched using game_id(numeric identifier) or absolute_name(symbols identifier).<br/>' +
        'Both values can be found in the response of Game/getList method or on your Client Area on the page of each game or on the All Games page by clicking the Download materials button in the "Games Data" file.',
    },
    {
      question: 'What steps need to be taken to go live?',
      answer:
        '1. You should integrate our API using files from your Client Area on the Documentation page.<br/>' +
        '2. Ensure that you have filled out all the sections of our callback test feature in the back office.<br/>' +
        '3. You should complete our manual testing on your website (performed by our team member).',
    },
    {
      question: "What does 'back office' mean exactly?",
      answer:
        'The projects can be configured in various back offices based on their server location. The address of the back office can be located in the respective API documentation.',
    },
    {
      question: 'Where can I find the project_id and secret_key?',
      answer:
        'project_id and secret_key can be found in the System settings tab of the back office.',
    },
    {
      question:
        'Can I request different back office permission rights for different users?',
      answer:
        'In the back office, there are various permission rights available. If you require a specific user to have admin access or limited access, you can provide the necessary details to our technical support team, and they will configure the corresponding permission rules for that user.',
    },
    {
      question: 'Where can I get login credentials for the back office?',
      answer:
        'Upon the registration of a new user, the login and password are automatically sent in an email. Without this email, it is not possible to log in, which emphasises the importance of providing a valid and accessible email address for back office registration. In the event that login credentials are misplaced, the "Forgot password" feature will be of assistance. It is essential to note that these emails will only be sent under two circumstances: when a new user is registered and when the "Forgot password" feature is utilised. If you already possess a back office user account and a new project/system is registered for you, no further emails will be sent.',
    },
    {
      question: 'Where are your servers located?',
      answer:
        'Our servers are located in the Netherlands, Germany (European servers), and Hong Kong (Asian servers).',
    },
    {
      question: 'Which timezone is used in the API?',
      answer: 'All the API methods work in UTC+0 timezone.',
    },
    {
      question: 'Do you have whitelist?',
      answer: 'There is no whitelist system available on our side.',
    },
  ],
  [ROLES.mediaAffiliate]: [
    {
      question: 'Who is eligible to access the Client Area?',
      answer:
        'The Client Area is accessible to registered media partners, affiliates, and reviewers who have been granted permission by our team.',
    },
    {
      question: 'What type of game materials can I download?',
      answer:
        'You can download a variety of game materials, including:<br/>' +
        '- Game logos, and media banners<br/>' +
        '- High-resolution images and artworks<br/>' +
        '- Game trailers and promotional videos<br/>' +
        '- Game descriptions and key features<br/>' +
        'By employing a diverse range of content formats (jpeg, psd, mp4), we ensure a well-rounded and immersive experience for your audience, catering to their preferences and capturing their attention across multiple channels.',
    },
    {
      question: 'Are there any restrictions on the use of game materials?',
      answer:
        'Please consult the <a href="/content/terms">Website terms and conditions page</a> to find out all necessary information on material usage.',
    },
    {
      question: "What should I do if I can't find the materials I need?",
      answer:
        'If you\'re unable to find specific game materials, please reach out to us at <a href="mailto:business@evoplay.games">business@evoplay.games</a>. We’ll assist you in locating the necessary resources.',
    },
    {
      question: 'How often are game materials updated?',
      answer:
        'We regularly update our game materials to reflect the latest releases and updates. You can check the <a href="/roadmap/evoplay">Roadmap</a> and <a href="/games/evoplay">All games</a> sections in the Evoplay Client Area for the latest materials.',
    },
    {
      question: 'Can I share downloaded materials with my team members?',
      answer:
        'Yes, you can share downloaded materials with your team members as long as it complies with the <a href="/content/terms">Website terms and conditions</a>. We recommend that team members also have their own access to the Client Area for direct downloads. To get access, please reach out to us at <a href="mailto:business@evoplay.games">business@evoplay.games</a>.',
    },
    {
      question: 'Can I use the game materials on social media?',
      answer:
        'Yes, you are allowed to use game materials on social media platforms for promotional purposes, provided that you adhere to our <a href="/brand-assets/evoplay">branding guidelines</a> and the <a href="/content/terms">Website terms and conditions page</a>.',
    },
    {
      question: 'How do I report a broken link or issue with a download?',
      answer:
        'If you encounter a broken link or any issue with downloading materials, please report it immediately by contacting us at <a href="mailto:business@evoplay.games">business@evoplay.games</a>.',
    },
    {
      question:
        'Are there any guidelines on how to use the game logos and artwork?',
      answer:
        'Yes, we provide branding guidelines that outline the correct usage of game logos, artwork, and other branding materials. These guidelines are available within the <a href="/brand-assets/evoplay">Brand assets</a> page.',
    },
  ],
};
