import { useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useGetPostQuery } from 'store/api/configService/postController';

import { findPathPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import Type from 'components/pages/Game/Type/Type';
import LatestNews from 'components/pages/NewsAndUpdates/LatestNews/LatestNews';
import NotFound from 'components/pages/NotFound/NotFound';

import { categories } from 'components/config/newsAndUpdates';
import { PAGE_PATH } from 'components/config/pages';

import styles from './News.module.scss';
import stylesGame from 'components/pages/Game/Game.module.scss';

const News = () => {
  const { item, category } = useParams();

  const { isLoading, data } = useGetPostQuery(item);

  if (isLoading) return <Loader />;

  if (!data) return <NotFound />;

  return (
    <>
      <div
        className={stylesGame.header}
        style={{
          backgroundImage: `url(${data.background})`,
          backgroundPosition: 'right',
        }}
      >
        <div className={stylesGame.content}>
          <div className={stylesGame.headerGradient}></div>
          <div className={stylesGame.headerContent}>
            <div className={stylesGame.back}>
              <ArrowCircleLink
                style={stylesGame.backLink}
                path={findPathPages(PAGE_PATH.NEWS)}
                title="Go to all news"
                direction="left"
              />
            </div>
            <div className={classNames(stylesGame.mainInfo, styles.mainInfo)}>
              <Type type={categories[category].title} />
              <div className={stylesGame.title}>
                <span>{data.title}</span>
              </div>
              <div>{data.date}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h3 dangerouslySetInnerHTML={{ __html: data.shortDescription }} />
        <div
          dangerouslySetInnerHTML={{ __html: data.description }}
          className={styles.contentShrink}
        />
      </div>
      <LatestNews />
    </>
  );
};

export default News;
