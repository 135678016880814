export const ROLES = {
  defaultUser: 'default-user',
  mediaAffiliate: 'media-affiliate',
};

export const PROVIDERS = {
  evoplay: 'evoplay',
  slotopia: 'slotopia',
};

export const EMAILS = {
  tech: '@evoplay.tech',
  games: '@evoplay.games',
  support: 'support@evoplay.games',
  business: 'business@evoplay.games',
};
