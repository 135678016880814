import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useS3Bucket } from 'hooks/s3';

import { show } from 'store/slices/alert';
import { setDialogData } from 'store/slices/downloadMaterials';

import {
  convertFolderPathToPrefix,
  generateGameMaterials,
  generatePromoMaterials,
  getGameMaterialsConfig,
  promoMaterialsConfig,
} from 'utils/generateMaterials';

import ContentLayout from 'components/Layouts/Content/ContentLayout';
import Loader from 'components/Loader/Loader';
import NotFound from 'components/pages/NotFound/NotFound';

import { gamePath, promotionsPath } from 'components/config/cloud';

import styles from './DownloadMaterials.module.scss';

const gameMaterialsConfig = getGameMaterialsConfig();

const DownloadMaterials = () => {
  const dispatch = useDispatch();

  const { type, path } = useParams();

  const config = useMemo(() => {
    switch (type) {
      case 'game':
        return {
          prefixes: `Games/${path}/`,
          formatItems: generateGameMaterials,
          getDialogData: (materials) => ({
            type: 'Game',
            materials,
            structure: gameMaterialsConfig.structure,
            folderPath: gamePath(path),
            downloadFileName: path,
            languageLabel: 'Languages',
            gamesData: [],
          }),
          errorText:
            'Data for this game is unavailable. Please check the URL for the correct game code.',
        };
      case 'promotions': {
        const folderPath = `${promotionsPath}/${path}/`;

        return {
          prefixes: `Promotions/${convertFolderPathToPrefix(folderPath)}/materials/`,
          formatItems: generatePromoMaterials,
          getDialogData: (materials) => ({
            type: 'Promo',
            materials,
            structure: promoMaterialsConfig.structure,
            folderPath,
            downloadFileName: path,
            languageLabel: 'Languages',
          }),
          errorText:
            'Data for this promotion is unavailable. Please check the URL for the correct promotion path.',
        };
      }
    }
  }, [type, path]);

  if (!config) return <NotFound />;

  const { fetchData, loading } = useS3Bucket({
    prefixes: config.prefixes,
    formatItems: config.formatItems,
  });

  const getMaterials = useCallback(async () => {
    const materials = await fetchData();

    dispatch(
      Object.keys(materials).length
        ? setDialogData(config.getDialogData(materials))
        : show({
            type: 'error',
            text: config.errorText,
            isAutoHide: false,
          }),
    );
  }, [fetchData, config]);

  useEffect(() => {
    getMaterials();
  }, []);

  return (
    <ContentLayout>
      <div className={styles.wrapper}>{loading && <Loader />}</div>
    </ContentLayout>
  );
};

export default DownloadMaterials;
