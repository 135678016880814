import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGameFilters } from 'hooks/gameFilters';
import { useS3Bucket } from 'hooks/s3';
import { useRole } from 'hooks/useRole';

import { useGetGamesListQuery } from 'store/api/configService/gameController';
import {
  allGamesSelector,
  clearSelectedItems,
  setSelectedItems,
} from 'store/slices/allGames';

import { searchGames } from 'utils/games';
import {
  generateDefaultGameMaterials,
  generateMultipleGameMaterials,
} from 'utils/generateMaterials';

import DownloadMaterials from 'components/DownloadMaterials/DownloadMaterials';
import Checkbox from 'components/UI/Checkbox/Checkbox';

import { gamesPath } from 'components/config/cloud';
import { materialsStructureByRole } from 'components/config/games';
import { plausibleEvents } from 'components/config/plausibleEvents';

import styles from 'components/pages/AllGames/Download/Download.module.scss';

const MAX_ITEMS_TO_FETCH = 5;

const Download = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const selectedItems = useSelector(allGamesSelector.getSelectedItems);
  const sortBy = useSelector(allGamesSelector.getSortBy);
  const search = useSelector(allGamesSelector.getSearch);

  const role = useRole();
  const { filters } = useGameFilters();

  const { data } = useGetGamesListQuery({
    sort: sortBy,
    filters,
    provider,
  });

  const { fetchData: fetchMaterials, loading } = useS3Bucket({
    prefixes: selectedItems.map((name) => `Games/${name}/`),
    formatItems: (items, prefixes) =>
      generateMultipleGameMaterials(items, prefixes, role),
  });

  const defaultMaterials = useMemo(
    () => generateDefaultGameMaterials(role),
    [role],
  );

  const filteredGames = useMemo(
    () => searchGames(data?.content, search)?.map(({ gameCode }) => gameCode),
    [data, search],
  );

  const isAllSelected = useMemo(
    () => filteredGames?.length === selectedItems.length,
    [filteredGames?.length, selectedItems.length],
  );

  const handleSelectAll = useCallback(
    () => dispatch(setSelectedItems(!isAllSelected ? filteredGames : [])),
    [isAllSelected, filteredGames],
  );

  const handleClear = useCallback(() => dispatch(clearSelectedItems()), []);

  useEffect(() => {
    selectedItems.length && handleClear();
  }, [filteredGames]);

  return (
    <div className={styles.wrapper}>
      <DownloadMaterials
        type="Game"
        gamesData="Selected"
        downloadFileName="Game materials"
        structure={materialsStructureByRole[role]}
        folderPath={gamesPath}
        disabled={!selectedItems.length}
        provider={provider}
        isAllGamesSelected={isAllSelected}
        trackEvent={{
          name: !isAllSelected
            ? plausibleEvents.clickDownload
            : plausibleEvents.clickDownloadAll,
          props: {
            page: `all-games-${provider}`,
          },
        }}
        {...(selectedItems.length > MAX_ITEMS_TO_FETCH
          ? { materials: defaultMaterials }
          : {
              fetchMaterials,
              selectedItems,
              loading,
            })}
      >
        <div className={styles.title}>Download materials</div>
        <div className={styles.subTitle}>
          Selected {selectedItems.length} game
          {selectedItems.length === 1 ? '' : 's'}
        </div>
      </DownloadMaterials>
      {!!filteredGames?.length && (
        <div className={styles.checkbox} onClick={handleSelectAll}>
          <Checkbox checked={isAllSelected} />
          <span>Select all</span>
        </div>
      )}
      {!!selectedItems.length && (
        <div className={styles.clear} onClick={handleClear}>
          Clear all
        </div>
      )}
    </div>
  );
};

export default Download;
