import { memo } from 'react';

import { useRole } from 'hooks/useRole';
import PropTypes from 'prop-types';

const RoleContentController = ({ roles, children }) => {
  const role = useRole();

  return children[roles.findIndex((item) => item === role)];
};

RoleContentController.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default memo(RoleContentController);
