import React from 'react';
import Flatpickr from 'react-flatpickr';

import PropTypes from 'prop-types';

import 'components/UI/Calendar/Range/Calendar.scss';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultDate: PropTypes.arrayOf(PropTypes.string),
};

const Calendar = React.forwardRef(function Calendar(
  { handleChange, defaultDate = [] },
  ref,
) {
  const options = {
    mode: 'range',
    dateFormat: 'd.m.Y',
    disableMobile: 'true',
    defaultDate,
    maxDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
    onValueUpdate: (_, dateStr, instance) => {
      const rangeDate = dateStr.split('to');
      if (rangeDate.length === 2) {
        instance.element.value = `From ${rangeDate[0]}to${rangeDate[1]}`;
      }
    },
    onChange: (_, dateStr) => {
      const rangeDate = dateStr.replace('From ', '').split(' to ');
      handleChange(rangeDate[0] === '' ? [] : rangeDate);
    },
    locale: {
      weekdays: {
        shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        longhand: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
      },
    },
  };

  return (
    <div className="calendar-range">
      <Flatpickr
        ref={ref}
        options={options}
        render={({ ...props }, ref) => (
          <input
            {...props}
            ref={ref}
            placeholder="Upload date"
            name="calendar range"
          />
        )}
      />
    </div>
  );
});

Calendar.propTypes = propTypes;
export default Calendar;
