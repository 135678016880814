import StorageCollection from 'utils/storage/StorageCollection';

export const initialData = {
  visible: {
    main: true,
    game: true,
  },
};

const storage = new StorageCollection('gameBannerState', initialData);

export const getStorageData = () => storage.getAll();
export const setStorageData = (data) => storage.add(data);
