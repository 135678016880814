import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import throttle from 'lodash.throttle';

import { show } from 'store/slices/alert';
import { certificateSelector, download } from 'store/slices/certificate';
import {
  clearFilters,
  documentsSelector,
  rangeDate,
  setFilter,
  setJurisdiction,
} from 'store/slices/documents';

import Loader from 'components/Loader/Loader';
import Calendar from 'components/UI/Calendar/Range/Calendar';
import RippleElement from 'components/UI/RippleElement/RippleElement';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import Select from 'components/UI/Select/Select';

import { Country } from 'components/config/certificate';

import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from './Document.module.scss';

const Document = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const searchRef = useRef(null);

  const [expand, setExpand] = useState('');

  const data = useSelector(documentsSelector.getData);

  const loader = useSelector(certificateSelector.getLoading);
  const errors = useSelector(certificateSelector.getError);

  const jurisdictionValue = useSelector(documentsSelector.getJurisdiction);
  const rangeValue = useSelector(documentsSelector.getRange);

  const handleClickClear = useCallback(() => {
    if (calendarRef?.current?.flatpickr) {
      calendarRef.current.flatpickr.clear();
    }
    if (searchRef?.current) {
      searchRef.current.value = '';
    }
    dispatch(clearFilters());
  }, [calendarRef]);
  const handleClickExpand = useCallback(
    (title) => setExpand((prevState) => (prevState !== title ? title : '')),
    [],
  );
  const handleChangeSearch = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(setFilter(event.target.value));
    }, 500),
    [],
  );
  const handleJurisdiction = useCallback(
    (_, value) => dispatch(setJurisdiction(value)),
    [],
  );
  const handleRange = useCallback((date) => dispatch(rangeDate(date)), []);

  const handleDownload = useCallback((game) => {
    dispatch(
      download({
        zipFileName: `${game.Name} [Documents - ${game.Jurisdiction}]`,
        urls: [game.Link],
      }),
    );
  }, []);

  useEffect(() => {
    if (errors) {
      dispatch(show({ type: 'error', text: errors }));
    }
  }, [errors]);

  return (
    <>
      <div className={styles.header}>
        <SearchInput
          ref={searchRef}
          handleChange={handleChangeSearch}
          customStyle={styles.search}
        />
        <Select
          handleSelect={handleJurisdiction}
          title="Jurisdiction"
          label="jurisdiction"
          items={Country}
          width={170}
          value={jurisdictionValue}
        />
        <Calendar
          handleChange={handleRange}
          ref={calendarRef}
          defaultDate={rangeValue}
        />
        {(!!rangeValue.length || jurisdictionValue) && (
          <a href="#" className={styles.clear} onClick={handleClickClear}>
            Clear all
          </a>
        )}
      </div>
      <div className={styles.tHead}>
        <div>Name</div>
        <div>Jurisdiction</div>
        <div>Date</div>
        <div>Download</div>
      </div>
      <div className={styles.scroll}>
        {data.map((game) => (
          <React.Fragment key={JSON.stringify(game)}>
            <div className={styles.tRow}>
              <div
                className={classNames({ [styles.hover]: game.subItems })}
                onClick={() => handleClickExpand(game.Name)}
              >
                {game.Name}
                {game.subItems && (
                  <div className={styles.arrowWrapper}>
                    <div
                      className={classNames(styles.arrow, {
                        [styles.arrowUp]: expand === game.Name,
                        [styles.arrowDown]: expand !== game.Name,
                      })}
                    ></div>
                  </div>
                )}
              </div>
              <div>{game.Jurisdiction}</div>
              <div>{game.Date}</div>
              <RippleElement onClick={() => handleDownload(game)}>
                <DownloadIcon />
              </RippleElement>
            </div>
            {game.subItems &&
              expand === game.Name &&
              game.subItems.map((item) => (
                <div
                  className={classNames(styles.tRow, styles.tRowSub)}
                  key={`${game.Name}${item.Name}`}
                >
                  <div>{item.Name}</div>
                  <div>{item.Jurisdiction}</div>
                  <div>{item.Date}</div>
                  <RippleElement onClick={() => {}}>
                    <DownloadIcon />
                  </RippleElement>
                </div>
              ))}
          </React.Fragment>
        ))}
      </div>
      {loader && <Loader type="content" />}
    </>
  );
};

export default Document;
