import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { useRole } from 'hooks/useRole';

import { setUserInfo } from 'store/slices/user';
import { userSelector } from 'store/slices/user';

import { getUserProfile } from 'services/UserService';
import { findPathPages } from 'utils/pages';

import GameBanner from 'components/GameBanner/GameBanner';
import AppLayout from 'components/Layouts/App/AppLayout';
import ContentLayout from 'components/Layouts/Content/ContentLayout';
import OnBoarding from 'components/OnBoarding/OnBoarding';
import PrivateRoute from 'components/PrivateRoutes/PrivateRoutes';
import Sidebar from 'components/Sidebar/Sidebar';
import ToggleSidebar from 'components/Sidebar/ToggleSidebar/ToggleSidebar';
// import TournamentPopup from 'components/TournamentPopup/TournamentPopup';
import NotFound from 'components/pages/NotFound/NotFound';

import { BASE_PATH, PAGE_PATH, Pages } from 'components/config/pages';
import growthbook from 'config/growthbook';

import 'styles/main.scss';

const App = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userSelector.getInfo);

  const role = useRole();

  useEffect(() => {
    getUserProfile().then((data) => dispatch(setUserInfo(data)));
    growthbook.init({ streaming: true });
  }, []);

  useEffect(() => {
    if (userInfo?.id) {
      growthbook.setAttributes({
        loggedIn: true,
        id: userInfo.id,
        url: window.location.href,
      });
    }
  }, [userInfo]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AppLayout>
        <Sidebar />
        <ContentLayout>
          <Routes>
            {Object.entries(Pages).map(
              ([key, { path, element, isEnabled }]) => {
                if (isEnabled && !isEnabled(role)) return null;

                return (
                  <Route
                    key={key}
                    path={path}
                    element={<PrivateRoute Component={element} />}
                  />
                );
              },
            )}
            <Route
              path={BASE_PATH}
              element={<Navigate to={findPathPages(PAGE_PATH.HOME)} replace />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ContentLayout>
      </AppLayout>
      <ToggleSidebar />
      <OnBoarding />
      {/*<TournamentPopup />*/}
      <GameBanner />
    </GrowthBookProvider>
  );
};
export default App;
