import { Languages } from 'components/config/languages';
import { ROLES } from 'config/organization';

export const prepareMaterials = (structure) =>
  Array.isArray(structure)
    ? structure
    : Object.keys(structure).reduce(
        (acc, row) => ({ ...acc, [row]: structure[row].items }),
        {},
      );

const defaultStructure = {
  game_data: {
    category: 'Game Data',
  },
  product_sheets: {
    file: 'Product_sheet.pdf',
    category: 'Product Sheet',
  },
  'Promo Pack': {
    file: 'Promo Pack.zip',
    category: 'Promotion Pack',
    expand: 'Files',
    open: true,
    subCategory: {
      Icons: {
        file: 'Icons.zip',
        category: 'Icons',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        showPreviews: true,
        items: {
          JPG: [
            '1920x250',
            '1800x400',
            '1000x700',
            '945x370',
            '720x340',
            '450x330',
            '446x186',
            '446x186',
            '440x310',
            '400x400',
            '383x200',
            '360x360',
            '354x210',
            '328x192',
            '315x250',
            '301x180',
            '300x225',
            '294x226',
            '288x375',
            '287x193',
            '253x228',
            '250x157',
            '242x182',
            '242x181',
            '210x210',
            '183x125',
            '160x160',
          ],
          PSD: [
            '1920x250',
            '1800x400',
            '1000x700',
            '945x370',
            '720x340',
            '450x330',
            '446x186',
            '446x186',
            '440x310',
            '400x400',
            '383x200',
            '360x360',
            '354x210',
            '328x192',
            '315x250',
            '301x180',
            '300x225',
            '294x226',
            '288x375',
            '287x193',
            '253x228',
            '250x157',
            '242x182',
            '242x181',
            '210x210',
            '183x125',
            '160x160',
          ],
        },
      },
      Media: {
        file: 'Media.zip',
        category: 'Media',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        showPreviews: true,
        items: {
          JPG: [
            '1920x1080',
            '1200x900',
            '1080x1092',
            '1080x1080',
            '580x580',
            '301x180',
            '300x650',
            '270x270',
            '152x152',
            '63x64',
          ],
          PSD: [
            '1920x1080',
            '1200x900',
            '1080x1092',
            '1080x1080',
            '580x580',
            '301x180',
            '300x650',
            '270x270',
            '152x152',
            '63x64',
          ],
        },
      },
      Assets: {
        file: 'Assets.zip',
        category: 'Assets',
        expand: 'Files',
        countColumns: 4,
        items: ['Background', 'Game Elements', 'Logo', 'Other'],
      },
      Video: {
        file: 'Video.zip',
        category: 'Video',
        expand: 'Sizes',
        countColumns: 5,
        pack: ['MP4'],
        items: {
          MP4: ['1920x1080', '1200x630', '1080x1920', '1080x1350', '1080x1080'],
        },
      },
    },
  },
  rules: {
    category: 'Rules',
    expand: 'Languages',
    subCategory: Object.keys(Languages),
  },
};

export const materialsStructureByRole = {
  [ROLES.defaultUser]: defaultStructure,
  [ROLES.mediaAffiliate]: {
    ...defaultStructure,
    'Promo Pack': {
      ...defaultStructure['Promo Pack'],
      subCategory: {
        ...defaultStructure['Promo Pack'].subCategory,
        Screenshots: {
          file: 'Screenshots.zip',
          category: 'Screenshots',
          expand: 'Sizes',
          countColumns: 4,
          pack: ['JPG'],
          showPreviews: true,
          items: {
            JPG: ['1920x1080_1', '1920x1080_2', '1920x1080_3', '1920x1080_4'],
          },
        },
      },
    },
  },
};
