import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';
import { isSafari } from 'config/client';

import GameBanner from 'images/GameBanners/Game.webm';
import GameBannerWebp from 'images/GameBanners/Game.webp';
import MainBanner from 'images/GameBanners/Main.webm';
import MainBannerWebp from 'images/GameBanners/Main.webp';

export const TYPES = {
  main: 'main',
  game: 'game',
};

export const CONFIG = {
  gameName: 'The Easter Catch',
  gamePath: findPathPages(PAGE_PATH.GAME).replace(
    ':gameCode',
    'the_easter_catch',
  ),
  demoUrl:
    'https://demo.evoplay.games/demo/fullstate/html5/evoplay/theeastercatch',
  mainBanner: !isSafari ? MainBanner : MainBannerWebp,
  gameBanner: !isSafari ? GameBanner : GameBannerWebp,
};
