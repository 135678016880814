import { api } from 'store/api/configService';

import { filterOptionsMap } from 'components/config/filters';
import { SortMap } from 'components/config/sort';

export const gameController = api.injectEndpoints({
  endpoints: (build) => ({
    getGamesList: build.query({
      query: ({ provider, sort, filters }) => ({
        url: '/config-service/client-area/v1/games',
        params: {
          sort: SortMap[sort],
          ...Object.entries({
            provider,
            ...filters,
          }).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: Array.isArray(value)
                ? value
                    .map((item) => filterOptionsMap[key]?.[item] ?? item)
                    .join(',')
                : value,
            }),
            {},
          ),
        },
      }),
    }),
    getGameInfo: build.query({
      query: ({ gameCode }) =>
        `/config-service/client-area/v1/games/${gameCode}`,
    }),
    getComingSoon: build.query({
      query: (params) => ({
        url: '/config-service/client-area/v1/games/coming-soon',
        params,
      }),
    }),
    getLatestReleases: build.query({
      query: (params) => ({
        url: '/config-service/client-area/v1/games/latest-releases',
        params,
      }),
    }),
    getRecommended: build.query({
      query: () => '/config-service/client-area/v1/games/recommended',
    }),
    getRoadmap: build.query({
      query: (params) => ({
        url: '/config-service/client-area/v1/games/roadmap',
        params,
      }),
    }),
  }),
});

export const {
  useGetGamesListQuery,
  useGetGameInfoQuery,
  useGetComingSoonQuery,
  useGetLatestReleasesQuery,
  useGetRecommendedQuery,
  useGetRoadmapQuery,
} = gameController;
