import React, { useCallback } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './RippleElement.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const RippleElement = ({
  onClick,
  disabled = false,
  className = null,
  children,
}) => {
  const createRipple = useCallback((event) => {
    if (disabled) {
      return null;
    }

    const button = event.currentTarget;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add(styles.ripple);

    const ripple = button.getElementsByClassName(styles.ripple)[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);

    onClick();
  }, []);

  return (
    <div
      onClick={createRipple}
      className={classNames(styles.wrapper, className)}
    >
      {children}
    </div>
  );
};

RippleElement.propTypes = propTypes;
export default RippleElement;
