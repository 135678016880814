import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import classNames from 'classnames';

import { close, gameBannerSelector, initialize } from 'store/slices/gameBanner';
import { sidebarSelector } from 'store/slices/sidebar';

import { triggerPlausibleEvent } from 'utils/plausible';

import GameDemo from 'components/GameDemo/GameDemo';
import Button from 'components/UI/Button/Button';
import Dialog from 'components/UI/Dialog/Dialog';

import { CONFIG, TYPES } from 'components/config/gameBanner';
import { plausibleEvents } from 'components/config/plausibleEvents';
import { isSafari } from 'config/client';

import { ReactComponent as CloseIcon } from 'images/Icons/Close.svg';

import styles from './GameBanner.module.scss';

const primaryButtonStyles = {
  height: '56px',
  padding: '10px 20px',
  fontWeight: '700',
  fontSize: '24px',
  textTransform: 'uppercase',
  borderRadius: '100px',
};

const secondaryButtonStyles = {
  width: '140px',
  borderColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
};

const { gameName, gamePath, demoUrl, mainBanner, gameBanner } = CONFIG;

const GameBanner = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [demoOpened, setDemoOpened] = useState(false);

  const visible = useSelector(gameBannerSelector.getVisible);
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);

  const isEnabled = useFeatureIsOn('ca-game-banner');

  const type = useMemo(
    () => (gamePath === pathname ? TYPES.game : TYPES.main),
    [pathname],
  );

  const isVisible = useMemo(() => visible[type], [visible, type]);

  const isMain = useMemo(() => type === TYPES.main, [type]);

  const mediaProps = useMemo(() => {
    const size = isMain ? '100%' : 'auto';
    const urlKey = !isSafari ? 'url' : 'src';

    return {
      [urlKey]: isMain ? mainBanner : gameBanner,
      width: size,
      height: size,
    };
  }, [isMain, isSafari]);

  const show = useMemo(() => isEnabled && isVisible, [isEnabled, isVisible]);

  const handleEvent = useCallback(
    (name, props = {}) =>
      triggerPlausibleEvent({
        name,
        props: {
          source: pathname,
          ...props,
        },
      }),
    [pathname],
  );

  const handleBannerClick = useCallback(
    () =>
      handleEvent(plausibleEvents.gameBannerClick, {
        event: 'other_click_area',
      }),
    [handleEvent],
  );

  const handleLinkClick = useCallback(
    () =>
      handleEvent(plausibleEvents.gameBannerClick, {
        event: 'game_name_click',
      }),
    [handleEvent],
  );

  const handleOpenDemo = useCallback(() => {
    setDemoOpened(true);

    handleEvent(plausibleEvents.gameBannerClick, {
      event: 'demo_click',
    });
  }, [handleEvent]);

  const handleCloseDemo = useCallback(() => setDemoOpened(false), []);

  const handleClose = useCallback(() => {
    dispatch(close(type));
    handleEvent(plausibleEvents.gameBannerClose);
  }, [type, handleEvent]);

  useEffect(() => {
    dispatch(initialize());
  }, []);

  useEffect(() => {
    show && handleEvent(plausibleEvents.gameBannerShow);
  }, [show, pathname]);

  if (!show) return null;

  return (
    <>
      <div
        className={classNames(styles.root, {
          [styles.main]: isMain,
          [styles.sidebarHidden]: !isVisibleSidebar,
        })}
        data-testid="game-banner-root"
      >
        <div
          onClick={handleBannerClick}
          className={styles.mediaWrapper}
          style={{ width: mediaProps.width, height: mediaProps.height }}
        >
          {!isSafari ? (
            <ReactPlayer
              {...mediaProps}
              data-testid="game-banner-video"
              playing
              muted
              loop
            />
          ) : (
            <img
              {...mediaProps}
              alt="game_banner"
              data-testid="game-banner-img"
            />
          )}
        </div>
        <CloseIcon
          onClick={handleClose}
          className={styles.closeIcon}
          data-testid="game-banner-close"
        />
        {isMain && (
          <>
            <Button
              component={Link}
              to={gamePath}
              onClick={handleLinkClick}
              sx={primaryButtonStyles}
              data-testid="game-banner-link"
            >
              {gameName}
            </Button>
            <Button
              variant="secondary"
              onClick={handleOpenDemo}
              sx={secondaryButtonStyles}
              data-testid="game-banner-demo-btn"
            >
              Play demo
            </Button>
          </>
        )}
      </div>
      {isMain && (
        <Dialog
          open={demoOpened}
          handleClose={handleCloseDemo}
          options={{ type: 'opacity', hideScroll: true }}
          data-testid="game-banner-demo-dialog"
        >
          <GameDemo src={demoUrl} />
        </Dialog>
      )}
    </>
  );
};

export default memo(GameBanner);
