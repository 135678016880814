import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useRole } from 'hooks/useRole';
import PropTypes from 'prop-types';

import {
  clearData,
  download,
  downloadMaterialsSelector,
  setLanguage,
  setWholeCategory,
} from 'store/slices/downloadMaterials';

import { triggerPlausibleEvent } from 'utils/plausible';

import Content from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content';
import Loader from 'components/Loader/Loader';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import Dialog from 'components/UI/Dialog/Dialog';

import { plausibleEvents } from 'components/config/plausibleEvents';

import styles from 'components/DownloadMaterials/Dialog/Dialog.module.scss';

const DownloadDialog = ({ pageView }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const loader = useSelector(downloadMaterialsSelector.getLoading);
  const downloadFilters = useSelector(downloadMaterialsSelector.getDownload);
  const language = useSelector(downloadMaterialsSelector.getLanguage);
  const {
    materials,
    structure,
    folderPath,
    type,
    gamesData,
    languageLabel,
    trackingPage,
    provider,
    isAllGamesSelected,
  } = useSelector(downloadMaterialsSelector.getDialogData);

  const role = useRole();

  const [expand, setExpand] = useState(
    structure[Object.keys(structure).find((el) => !!structure[el].open)]
      ?.category || '',
  );

  const handleClickExpand = useCallback((expandRow) => {
    setExpand((prevState) => (prevState !== expandRow ? expandRow : ''));
  }, []);

  const handleCloseDlg = useCallback(() => {
    if (pageView) return;

    setExpand('');
    dispatch(clearData());
  }, [pageView]);

  const handleClickCategory = useCallback(
    (category) => dispatch(setWholeCategory({ category })),
    [],
  );

  const handleClickLng = useCallback((lng) => dispatch(setLanguage(lng)), []);

  const hasElements = useMemo(() => {
    if (downloadFilters.wholeCategory.length) {
      return true;
    }
    if (Object.keys(downloadFilters.category).length) {
      return Object.keys(downloadFilters.category).some(
        (category) => !!downloadFilters.category[category].length,
      );
    }
    if (Object.keys(downloadFilters.pack).length) {
      return Object.keys(downloadFilters.pack).some((category) =>
        Object.keys(downloadFilters.pack[category]).some(
          (pack) => !!downloadFilters.pack[category][pack].length,
        ),
      );
    }
    if (Object.keys(downloadFilters.items).length) {
      return Object.keys(downloadFilters.items).some((category) =>
        Object.keys(downloadFilters.items[category]).some((row) => {
          if (Array.isArray(downloadFilters.items[category][row])) {
            return !!downloadFilters.items[category][row].length;
          }

          return Object.keys(downloadFilters.items[category][row]).some(
            (pack) => !!downloadFilters.items[category][row][pack].length,
          );
        }),
      );
    }
    return false;
  }, [downloadFilters]);

  const isAllMaterialsSelected = useMemo(
    () =>
      !!materials?.[language] &&
      Object.entries(materials[language]).every(([category, data]) => {
        if (downloadFilters.wholeCategory.includes(category)) return true;

        const dataKeys = Object.keys(data);

        return (
          dataKeys.length &&
          dataKeys.every((subCategory) => {
            if (downloadFilters.category[category]?.includes(subCategory)) {
              return true;
            }

            if (downloadFilters.items[category]?.[subCategory]) {
              const subCategoryData = data[subCategory];

              if (Array.isArray(subCategoryData)) {
                return subCategoryData.every((item) =>
                  downloadFilters.items[category][subCategory].includes(item),
                );
              }

              return Object.entries(subCategoryData).every(
                ([pack, packItems]) =>
                  packItems.every((item) =>
                    downloadFilters.items[category]?.[subCategory]?.[
                      pack
                    ]?.includes(item),
                  ) ||
                  downloadFilters.pack[category]?.[subCategory]?.includes(pack),
              );
            }
          })
        );
      }),
    [materials, language, downloadFilters],
  );

  const folderPathByType = useMemo(() => {
    switch (type) {
      case 'Promo':
      case 'Bingo':
        return {
          folderPath: `${folderPath}materials/${language}/`,
        };
      case 'Game':
        return ['All', 'Selected'].includes(gamesData)
          ? {
              folderPath,
              generateFolderPath: (gameCode) =>
                `${folderPath}${gameCode}/${language}/`,
            }
          : {
              folderPath: `${folderPath}${language}/`,
            };
      default:
        return {
          folderPath,
        };
    }
  }, [type, folderPath, language, gamesData]);

  const createEvent = useCallback(
    (content) => ({
      name: !isAllGamesSelected
        ? plausibleEvents.keyConversionDownload
        : plausibleEvents.keyConversionDownloadAll,
      props: {
        page: trackingPage,
        content,
      },
    }),
    [isAllGamesSelected, trackingPage],
  );

  const trackEvents = useMemo(() => {
    if (isAllMaterialsSelected) {
      return [createEvent(`all-game-materials-${provider}`)];
    }

    const categoryKeys = Object.keys(downloadFilters.category).filter(
      (key) => downloadFilters.category[key].length,
    );

    const packKeys = Object.keys(downloadFilters.pack).filter((key) =>
      Object.values(downloadFilters.pack[key]).some((value) => value.length),
    );

    const itemKeys = Object.keys(downloadFilters.items).filter((key) =>
      Object.values(downloadFilters.items[key]).some((items) =>
        Array.isArray(items)
          ? items.length
          : Object.values(items).some((value) => value.length),
      ),
    );

    const eventKeys = [
      ...new Set([
        ...downloadFilters.wholeCategory,
        ...categoryKeys,
        ...packKeys,
        ...itemKeys,
      ]),
    ];

    return eventKeys.map((key) =>
      createEvent(
        `${key.toLowerCase().replace(' ', '').replace('_', '-')}-${provider}`,
      ),
    );
  }, [isAllMaterialsSelected, createEvent, downloadFilters, provider]);

  const actions = useMemo(
    () => [
      {
        title: 'Download',
        action: () => {
          trackEvents.forEach((event) => triggerPlausibleEvent(event));

          dispatch(
            download({
              role,
              downloadFilters,
              clearDialogData: !pageView,
              ...folderPathByType,
            }),
          );
        },
        props: { disabled: !hasElements },
      },
    ],
    [
      trackEvents,
      role,
      downloadFilters,
      pageView,
      folderPathByType,
      hasElements,
    ],
  );

  useEffect(() => {
    return handleCloseDlg;
  }, [pathname]);

  return (
    <Dialog
      open={!loader}
      handleClose={handleCloseDlg}
      actions={actions}
      withCloseBtn={!pageView}
    >
      {loader && <Loader type="content" />}
      {materials && Object.keys(materials).length > 1 && (
        <div className={styles.languages}>
          <div className={styles.languagesLabel}>{languageLabel}</div>
          {Object.keys(materials).map((item) => (
            <div
              key={item}
              className={classNames(styles.languagesTag, {
                [styles.active]: language === item,
              })}
              onClick={() => handleClickLng(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
      {Object.keys(structure).map((item) => {
        if (materials && !materials[language][item]) return null;

        return (
          <div className={styles.row} key={item}>
            <div className={styles.header}>
              <div
                className={styles.title}
                onClick={() => handleClickCategory(item)}
              >
                <Checkbox
                  checked={downloadFilters.wholeCategory.includes(item)}
                />
                <span>{structure[item].category}</span>
              </div>
              {structure[item].expand && (
                <div
                  className={classNames(styles.expand, {
                    [styles.expandActive]: expand === item,
                  })}
                  onClick={() => handleClickExpand(structure[item].category)}
                >
                  <span>{structure[item].expand}</span>
                  <div className={styles.arrowWrapper}>
                    <div
                      className={classNames(styles.arrow, {
                        [styles.arrowUp]: expand === structure[item].category,
                        [styles.arrowDown]: expand !== structure[item].category,
                      })}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            {expand === structure[item].category && (
              <Content
                materials={materials ? materials[language][item] : {}}
                structure={structure[item].subCategory}
                category={item}
                type={type}
                provider={provider}
                folderProps={folderPathByType}
              />
            )}
          </div>
        );
      })}
    </Dialog>
  );
};

DownloadDialog.propTypes = {
  pageView: PropTypes.bool,
};

export default DownloadDialog;
