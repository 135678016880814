import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import Certificate from 'components/pages/Compliance/Certificate/Certificate';
import Document from 'components/pages/Compliance/Document/Document';

import styles from './Compliance.module.scss';

const Compliance = () => {
  const [tab, setTab] = useState('certificate');
  const handleClick = useCallback((tab) => setTab(tab), []);

  return (
    <>
      <div className={styles.header}>
        <div className={classNames(styles.tabWrapper, styles.content)}>
          <div
            className={classNames(styles.tab, {
              [styles.active]: tab === 'certificate',
            })}
            onClick={() => handleClick('certificate')}
          >
            Game Certificates
          </div>
          <div
            className={classNames(styles.tab, {
              [styles.active]: tab === 'document',
            })}
            onClick={() => handleClick('document')}
          >
            Other Documents
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {tab === 'certificate' && <Certificate />}
        {tab === 'document' && <Document />}
      </div>
    </>
  );
};

export default Compliance;
