import { createSlice } from '@reduxjs/toolkit';

import { getSessionId } from 'services/UserService';
import {
  getStorageData,
  initialData,
  setStorageData,
} from 'utils/storage/gameBanner';
import {
  getStorageData as getSessionIdStorageData,
  setStorageData as setSessionIdStorageData,
} from 'utils/storage/sessionId';

const initialState = {
  visible: getStorageData().visible,
};

const gameBannerSlice = createSlice({
  name: 'gameBanner',
  initialState,
  reducers: {
    initialize: (state) => {
      const currentSessionId = getSessionId();

      if (getSessionIdStorageData()?.value !== currentSessionId) {
        if (Object.values(state.visible).some((value) => !value)) {
          state.visible = initialData.visible;
          setStorageData({ visible: initialData.visible });
        }

        setSessionIdStorageData({ value: currentSessionId });
      }
    },
    close: (state, { payload: type }) => {
      state.visible[type] = false;
      setStorageData({
        visible: {
          ...getStorageData().visible,
          [type]: false,
        },
      });
    },
  },
});

export const gameBannerSelector = {
  getVisible: (state) => state.gameBanner.visible,
};

export const { initialize, close } = gameBannerSlice.actions;
export default gameBannerSlice.reducer;
