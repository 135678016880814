import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useAccessView from 'hooks/tournaments/accessView';
import { useRole } from 'hooks/useRole';

import { sidebarSelector } from 'store/slices/sidebar';

import Copyright from 'components/Copyright/Copyright';
import Logo from 'components/Logo/Logo';
import ContentLinks from 'components/Sidebar/ContentLinks/ContentLinks';
import Item from 'components/Sidebar/Item/Item';

import { items } from 'components/config/sidebar';

import styles from 'components/Sidebar/Sidebar.module.scss';

const Sidebar = () => {
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);

  const role = useRole();
  const hasTournamentsAccess = useAccessView();

  const orderedItems = useMemo(
    () => [...items].sort((a, b) => a.getOrder(role) - b.getOrder(role)),
    [role],
  );

  return (
    <div className={styles.wrapper} data-testid="sidebar-root">
      <div>
        <Logo />
        <div>
          {orderedItems.map(({ isVisible, ...item }) => {
            if (
              isVisible &&
              !isVisible({
                role,
                hasTournamentsAccess,
              })
            )
              return null;

            return <Item key={item.title} {...item} />;
          })}
        </div>
      </div>
      {isVisibleSidebar && (
        <div className={styles.footer} data-testid="sidebar-footer">
          <ContentLinks />
          <Copyright />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
