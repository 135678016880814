import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { clear, finish, process } from 'store/slices/downloadProcess';

import { downloadMaterials } from 'utils/download';

import { gamesPath } from 'components/config/cloud';

const initialState = {
  loading: false,
  error: null,
  dialogData: {},
  language: 'English',
  wholeCategory: [],
  category: {},
  pack: {},
  items: {},
};

export const download = createAsyncThunk(
  'downloadMaterials/download',
  async (
    { role, downloadFilters, folderPath, generateFolderPath, clearDialogData },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const {
        downloadMaterials: { dialogData },
      } = getState();

      const onProgress = (url, percent) => {
        let item = '';
        if (
          dialogData.type === 'Game' &&
          ['All', 'Selected'].includes(dialogData.gamesData)
        ) {
          item = `<b>${
            url.split(gamesPath).pop().replace(/\+/g, ' ').split('/')[0]
          }</b>&nbsp;`;
        }
        const name = `${item}${url.split('/').pop()}`;
        if (percent < 99) {
          dispatch(
            process({
              name,
              percent,
            }),
          );
        } else {
          dispatch(finish(name));
        }
      };

      const onFinish = () => dispatch(clear());

      return downloadMaterials(
        role,
        downloadFilters,
        folderPath,
        generateFolderPath,
        getState,
        dispatch,
        rejectWithValue,
        onProgress,
        onFinish,
        clearDialogData,
      );
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

export const downloadMaterialsSlice = createSlice({
  name: 'downloadMaterials',
  initialState,
  reducers: {
    setWholeCategory: (state, action) => {
      const { category } = action.payload;
      const wholeCategory = new Set(state.wholeCategory);
      if (wholeCategory.has(category)) {
        wholeCategory.delete(category);
      } else {
        wholeCategory.add(category);
      }
      state.wholeCategory = Array.from(wholeCategory);

      downloadMaterialsSlice.caseReducers.clearCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearPack(state, action);
      downloadMaterialsSlice.caseReducers.clearItems(state, action);
    },
    clearWholeCategory: (state, action) => {
      const { category } = action.payload;

      const wholeCategory = new Set(state.wholeCategory);
      wholeCategory.delete(category);
      state.wholeCategory = Array.from(wholeCategory);
    },
    setCategory: (state, action) => {
      const { category, row } = action.payload;

      if (!state.category[category]) {
        state.category[category] = [];
      }

      const categorySet = new Set(state.category[category]);
      if (categorySet.has(row)) {
        categorySet.delete(row);
      } else {
        categorySet.add(row);
      }

      state.category[category] = Array.from(categorySet);

      downloadMaterialsSlice.caseReducers.clearWholeCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearPack(state, action);
      downloadMaterialsSlice.caseReducers.clearItems(state, action);
    },
    clearCategory: (state, action) => {
      const { category, row } = action.payload;

      if (!row) {
        delete state.category[category];
        return;
      }

      if (state.category[category]) {
        const categorySet = new Set(state.category[category]);
        categorySet.delete(row);
        if (categorySet.size) {
          state.category[category] = Array.from(categorySet);
        } else {
          delete state.category[category];
        }
      }
    },
    setPack: (state, action) => {
      const { category, row, pack } = action.payload;

      if (!state.pack[category]) {
        state.pack[category] = {};
      }
      if (!state.pack[category][row]) {
        state.pack[category][row] = [];
      }

      const packSet = new Set(state.pack[category][row]);
      if (packSet.has(pack)) {
        packSet.delete(pack);
      } else {
        packSet.add(pack);
      }

      if (packSet.size) {
        state.pack[category][row] = Array.from(packSet);
      } else {
        delete state.pack[category][row];
      }

      downloadMaterialsSlice.caseReducers.clearWholeCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearItems(state, action);
    },
    clearPack: (state, action) => {
      const { category, row, pack } = action.payload;

      if (!row) {
        delete state.pack[category];
        return;
      }

      if (state.pack[category] && state.pack[category][row]) {
        if (!pack) {
          delete state.pack[category][row];
          return;
        }

        const packSet = new Set(state.pack[category][row]);
        packSet.delete(pack);
        if (packSet.size) {
          state.pack[category][row] = Array.from(packSet);
        } else {
          delete state.pack[category][row];
        }
      }

      if (state.pack[category] && !Object.keys(state.pack[category]).length) {
        state.pack = initialState.pack;
      }
    },
    setItems: (state, action) => {
      const { category, row, pack, el } = action.payload;

      if (!state.items[category]) {
        state.items[category] = {};
      }
      if (!pack) {
        if (!state.items[category][row]) {
          state.items[category][row] = [];
        }

        const elSet = new Set(state.items[category][row]);
        if (elSet.has(el)) {
          elSet.delete(el);
        } else {
          elSet.add(el);
        }

        if (elSet.size) {
          state.items[category][row] = Array.from(elSet);
        } else {
          delete state.items[category][row];
        }
      } else {
        if (!state.items[category][row]) {
          state.items[category][row] = {};
        }
        if (!state.items[category][row][pack]) {
          state.items[category][row][pack] = [];
        }

        const elSet = new Set(state.items[category][row][pack]);
        if (elSet.has(el)) {
          elSet.delete(el);
        } else {
          elSet.add(el);
        }

        if (elSet.size) {
          state.items[category][row][pack] = Array.from(elSet);
        } else {
          delete state.items[category][row][pack];
        }
      }

      downloadMaterialsSlice.caseReducers.clearWholeCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearPack(state, action);
    },
    setPreviewItems: (state, action) => {
      const { category, row, pack, items } = action.payload;

      if (!state.items[category]) {
        state.items[category] = {};
      }

      if (!state.items[category][row]) {
        state.items[category][row] = {};
      }

      if (!state.items[category][row][pack]) {
        state.items[category][row][pack] = [];
      }

      state.items[category][row][pack] = items;

      downloadMaterialsSlice.caseReducers.clearWholeCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearCategory(state, action);
      downloadMaterialsSlice.caseReducers.clearPack(state, action);
    },
    clearItems: (state, action) => {
      const { category, row, pack } = action.payload;

      if (!category) {
        state.items = {};
        return;
      }

      if (!row && state.items[category]) {
        delete state.items[category];
        return;
      }

      if (!pack && state.items[category] && state.items[category][row]) {
        delete state.items[category][row];
        return;
      }

      if (
        state.items[category] &&
        state.items[category][row] &&
        state.items[category][row][pack]
      ) {
        delete state.items[category][row][pack];
      }

      if (state.items[category] && !Object.keys(state.items[category]).length) {
        state.items = initialState.items;
      }
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDialogData: (state, action) => {
      if (action.payload.materials) {
        state.language = Object.keys(action.payload.materials)[0];
      }
      state.dialogData = action.payload;
    },
    clearData: (state) => {
      state.dialogData = initialState.dialogData;
      state.language = initialState.language;
      state.wholeCategory = initialState.wholeCategory;
      state.category = initialState.category;
      state.pack = initialState.pack;
      state.items = initialState.items;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    const { pending, fulfilled, rejected } = download;
    builder
      .addCase(pending, (state) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(fulfilled, (state, { payload }) =>
        payload.clearDialogData
          ? initialState
          : { ...initialState, dialogData: state.dialogData },
      )
      .addCase(rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const downloadMaterialsSelector = {
  getLoading: (state) => state.downloadMaterials.loading,
  getError: (state) => state.downloadMaterials.error,
  getLanguage: (state) => state.downloadMaterials.language,
  getDialogData: (state) => state.downloadMaterials.dialogData,
  getDownload: createSelector(
    [
      (state) => state.downloadMaterials.wholeCategory,
      (state) => state.downloadMaterials.category,
      (state) => state.downloadMaterials.pack,
      (state) => state.downloadMaterials.items,
    ],
    (wholeCategory, category, pack, items) => ({
      wholeCategory,
      category,
      pack,
      items,
    }),
  ),
};
export const {
  clearData,
  clearError,
  setWholeCategory,
  setCategory,
  setPack,
  setItems,
  setPreviewItems,
  setLanguage,
  setDialogData,
} = downloadMaterialsSlice.actions;
export default downloadMaterialsSlice.reducer;
