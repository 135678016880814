import sidebarSlice, { sidebarMiddleware } from './slices/sidebar';
import { configureStore } from '@reduxjs/toolkit';

import { api as configServiceApi } from 'store/api/configService';
import { rtkQueryErrorLogger } from 'store/api/errorLogger';
import { api as tournamentServiceApi } from 'store/api/tournamentService/recipe.api';
import alertSlice from 'store/slices/alert';
import allGamesSlice, { allGamesMiddleware } from 'store/slices/allGames';
import certificateSlice from 'store/slices/certificate';
import documentsSlice from 'store/slices/documents';
import downloadMaterialsSlice from 'store/slices/downloadMaterials';
import downloadProcessSlice from 'store/slices/downloadProcess';
import gameBannerSlice from 'store/slices/gameBanner';
import onboardingSlice, { onboardingMiddleware } from 'store/slices/onboarding';
import roadmapSlice, { roadmapMiddleware } from 'store/slices/roadmap';
import tournamentPopupSlice, {
  tournamentPopupMiddleware,
} from 'store/slices/tournamentPopup';
import userSlice from 'store/slices/user';
import viewModeSlice from 'store/slices/viewMode';

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    viewMode: viewModeSlice,
    allGames: allGamesSlice,
    roadmap: roadmapSlice,
    documents: documentsSlice,
    certificate: certificateSlice,
    alert: alertSlice,
    downloadMaterials: downloadMaterialsSlice,
    downloadProcess: downloadProcessSlice,
    onboarding: onboardingSlice,
    user: userSlice,
    tournamentPopup: tournamentPopupSlice,
    gameBanner: gameBannerSlice,
    [tournamentServiceApi.reducerPath]: tournamentServiceApi.reducer,
    [configServiceApi.reducerPath]: configServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      sidebarMiddleware,
      allGamesMiddleware,
      roadmapMiddleware,
      onboardingMiddleware,
      tournamentPopupMiddleware,
      tournamentServiceApi.middleware,
      configServiceApi.middleware,
      rtkQueryErrorLogger,
    ),
});

export default store;
