import { api } from 'store/api/configService';

export const rulesController = api.injectEndpoints({
  endpoints: (build) => ({
    rulesDownload: build.query({
      query: ({ languages, gameCodes }) => ({
        url: '/config-service/client-area/v1/games/rules/download',
        method: 'POST',
        body: {
          languages,
          gameCodes,
        },
        responseHandler: async (response) =>
          URL.createObjectURL(await response.blob()),
      }),
    }),
  }),
});

export const { getRules } = rulesController;
