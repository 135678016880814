import { api } from 'store/api/configService';

const betController = api.injectEndpoints({
  endpoints: (build) => ({
    getBetCurrency: build.query({
      query: ({ currency, gameCode }) => ({
        url: '/config-service/api/v1/bet-currency',
        params: { currency, game_code: gameCode },
      }),
    }),
  }),
});

export const { useGetBetCurrencyQuery } = betController;
