import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import throttle from 'lodash.throttle';

import { show } from 'store/slices/alert';
import {
  certificateSelector,
  download,
  filter,
  sortBy,
} from 'store/slices/certificate';
import { viewModeSelector } from 'store/slices/viewMode';

import { VIEW_MODE } from 'utils/viewMode';

import Loader from 'components/Loader/Loader';
import Button from 'components/UI/Button/Button';
import RippleElement from 'components/UI/RippleElement/RippleElement';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import Select from 'components/UI/Select/Select';
import Tooltip from 'components/UI/Tooltip/Tooltip';

import {
  Country,
  ITALY_DOWNLOAD_MSG,
  getCountryMap,
} from 'components/config/certificate';
import {
  gameJurisdictionListPath,
  gamePath,
  getCertificate,
} from 'components/config/cloud';
import { Sort } from 'components/config/sort';

import { ReactComponent as Download2Icon } from 'images/Icons/Download2.svg';
import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from './Certificate.module.scss';

const Certificate = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const data = useSelector(certificateSelector.getData);
  const sortByValue = useSelector(certificateSelector.getSort);
  const loader = useSelector(certificateSelector.getLoading);
  const errors = useSelector(certificateSelector.getError);
  const filterStr = useSelector(certificateSelector.getFilter);
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const widthFirstColumn = viewMode === VIEW_MODE.SIMPLE ? 280 : 460;

  const handleFilter = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(filter(event.target.value));
    }, 500),
    [],
  );
  const handleSort = useCallback((_, value) => dispatch(sortBy(value)), []);
  const getCertificatePathByCountry = (game, country) =>
    `${gamePath(game.GameCode)}Certificates/${country}.zip`;
  const handleDownload = useCallback((game, country) => {
    if (country === 'All') {
      dispatch(
        download({
          zipFileName: `${game.Game} [Certificates]`,
          urls: [getCertificate(game.GameCode)],
        }),
      );
      return;
    }
    dispatch(
      download({
        zipFileName: `${game.Game} [Certificate - ${country}]`,
        urls: [getCertificatePathByCountry(game, country)],
      }),
    );
  }, []);
  const handleDownloadByCountry = useCallback((country) => {
    if (country === 'Italy') {
      dispatch(show({ type: 'info', text: ITALY_DOWNLOAD_MSG }));
      return;
    }
    const urls = data.reduce(
      (acc, item) =>
        item[country]
          ? acc.concat(getCertificatePathByCountry(item, country))
          : acc,
      [],
    );
    dispatch(
      download({
        zipFileName: `${country} [Certificates]`,
        schema: 'certificates',
        urls,
      }),
    );
  }, []);

  useEffect(() => {
    if (errors) {
      dispatch(show({ type: 'error', text: errors }));
    }
  }, [errors]);

  useEffect(() => {
    if (filterStr.length && inputRef) {
      inputRef.current.value = filterStr;
    }
  }, [inputRef]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.searchWrapper}>
          <SearchInput
            ref={inputRef}
            handleChange={handleFilter}
            customStyle={styles.search}
          />
          <Select
            handleSelect={handleSort}
            title="Sort By"
            label="sortBy"
            items={Sort}
            value={sortByValue}
          />
        </div>
        <Button
          variant="icon"
          target="_blank"
          rel="noreferrer"
          component={Link}
          startIcon={<DownloadIcon />}
          to={gameJurisdictionListPath}
          download
        >
          <div className={styles.btnTitle}>Game Jurisdiction List</div>
        </Button>
      </div>
      <div
        className={styles.tHead}
        style={{
          gridTemplateColumns: `${widthFirstColumn}px repeat(${Country.length}, 1fr)`,
        }}
      >
        <div>Game name</div>
        {Country.map((country) => (
          <RippleElement
            key={country}
            onClick={() => handleDownloadByCountry(getCountryMap(country))}
          >
            <Download2Icon />
            {country}
          </RippleElement>
        ))}
      </div>
      <div className={styles.scroll}>
        {data.map((item) => (
          <div
            key={item.Game}
            className={styles.tRow}
            style={{
              gridTemplateColumns: `${widthFirstColumn}px repeat(${Country.length}, 1fr)`,
            }}
          >
            <RippleElement onClick={() => handleDownload(item, 'All')}>
              <Download2Icon />
              {item.Game}
            </RippleElement>
            {Country.map((country) => (
              <RippleElement
                key={`${item.Game}-${country}`}
                className={classNames({
                  [styles.empty]: ![1, 2].includes(
                    item[getCountryMap(country)],
                  ),
                })}
                disabled={![1, 2].includes(item[getCountryMap(country)])}
                onClick={() => handleDownload(item, getCountryMap(country))}
              >
                {item[getCountryMap(country)] === 1 ? (
                  <DownloadIcon />
                ) : item[getCountryMap(country)] === 2 ? (
                  <div className={styles.new}>
                    new <DownloadIcon />
                  </div>
                ) : item[getCountryMap(country)] === 3 ? (
                  <Tooltip title={ITALY_DOWNLOAD_MSG}>
                    <div className={styles.disabled}>
                      <DownloadIcon />
                    </div>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </RippleElement>
            ))}
          </div>
        ))}
      </div>
      {loader && <Loader type="content" />}
    </>
  );
};

export default Certificate;
